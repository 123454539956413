import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://8a21bb9a01a54aadd63bfa1f23176a19@o4505596107554816.ingest.sentry.io/4505676408160256",
  integrations: [
    // new Sentry.BrowserTracing({
    //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ["localhost", "https:auotiv.com"],
    // }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});