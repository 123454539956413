import React, { Fragment } from "react";
import { Script, ScriptStrategy } from "gatsby";

interface Props {
  children: any;
}

const userwayAccountId = process.env.GATSBY_USERWAY_ACCOUNT_ID;

const RootElement = ({ children }: Props) => {
  return (
    <Fragment>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA_TRACKING_ID}`}
        strategy={ScriptStrategy.postHydrate}
        forward={[`gtag`]}
      />
      <Script
        id="gtag-config"
        strategy={ScriptStrategy.postHydrate}
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments);}
          gtag('js', new Date()); 
          gtag('config', '${process.env.GATSBY_GA_TRACKING_ID}', { send_page_view: false })`,
        }}
      />
      {userwayAccountId && (
        <Script
          src={`https://cdn.userway.org/widget.js`}
          data-account={userwayAccountId}
          strategy={ScriptStrategy.postHydrate}
        />
      )}
      <div>{children}</div>
    </Fragment>
  );
};

export default RootElement;
