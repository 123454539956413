import React from "react";
import RootElement from "../shared/src/react-components/RootElement/RootElement";

import "./src/styles/global.css";
import "../shared/src/styles/shared.css";

import "../shared/src/components/ArrowButton/ArrowButton.css";
import "../shared/src/components/BuilderButton/BuilderButtonHover.css";
import "../shared/src/components/NetlifyForm/NetlifyForm.css";

import "@fontsource/pt-sans/400.css";
import "@fontsource/pt-sans/700.css";

function initCookieYes() {
  if (window.cookieYesDidInit) {
    return false;
  }

  window.cookieYesDidInit = true; // flag to ensure script does not get added to DOM more than once.

  var script = document.createElement("script");

  script.type = "text/javascript";
  script.async = true;
  script.src =
    "https://cdn-cookieyes.com/client_data/760b9e5cddc9094152f6b59f/script.js";

  document.head.appendChild(script);
}

const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "; expires=" + date.toGMTString();
  document.cookie = name + "=" + value + expires + ";path=/";
};

const getParam = (p) => {
  const match = RegExp("[?&]" + p + "=([^&]*)").exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};

const storeCookies = () => {
  const gclid = getParam("gclid");
  if (gclid) {
    var gclsrc = getParam("gclsrc");
    if (!gclsrc || gclsrc.indexOf("aw") !== -1) {
      setCookie("gclid", gclid, 90);
    }
  }
};

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== "production") {
    return null;
  }
  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined;
  setTimeout(() => {
    if (typeof window.gtag === "function") {
      window.gtag("event", "page_view", { page_path: pagePath });
    }
  }, 100);
  return true;
};

export const onClientEntry = () => {
  storeCookies();
};

export const wrapRootElement = ({ element }) => {
  return (
    <RootElement>
      {element}
    </RootElement>
  );
};
